import { useState } from 'react';

const useLoading = () => {
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState({});

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const startLoadingWithKey = (key) => {
    if (!key) return;
    if (Array.isArray(key)) {
      setLoadings({
        ...loadings,
        ...key.reduce((acc, k) => ({ ...acc, [k]: true }), {}),
      });
    } else if (typeof key === 'string') {
      setLoadings({
        ...loadings,
        [key]: true,
      });
    }
  };

  const stopLoadingWithKey = (key) => {
    if (!key) return;
    if (Array.isArray(key)) {
      setLoadings({
        ...loadings,
        ...key.reduce((acc, k) => ({ ...acc, [k]: false }), {}),
      });
    } else if (typeof key === 'string') {
      setLoadings({
        ...loadings,
        [key]: false,
      });
    }
  };

  return {
    loading,
    startLoading,
    stopLoading,
    loadings,
    startLoadingWithKey,
    stopLoadingWithKey,
  };
};

export default useLoading;
